import { Observable } from 'rxjs';
import { LoginResponse, LoginRequest } from '../dto/login.dto';

export abstract class AuthRepository {
  abstract login(data: LoginRequest): Observable<LoginResponse>;

  abstract logout(): void;

  abstract getEmail(): string;

  abstract getToken(): string;

  abstract isLogged(): boolean;

}
